// This implementation is for the new VGS implementation to enable 3D secure on EU cards
import { VGS_V2_SCRIPT_SOURCE } from '@/settings/CreditCard';
import Country from '@/util/Country';
import { COUNTRY_ADDRESS_FORM_TYPES } from '@/settings/AddressForm';

export default {
	data() {
		return {
			state: new Country(),
			countryIso: new Country(),
			lacoreV2CreditCardErrors: {},
			lacoreV2PaymentForm: {},
			lacoreV2Failed: false,
			lacoreV2PaymentToken: null,
			lacoreV2ApplicationId: process.env.VUE_APP_LACORE_V2_APPLICATION_ID,
			lacoreV2Environment: process.env.VUE_APP_LACORE_V2_ENVIRONMENT,
			lacoreV2VaultId: process.env.VUE_APP_LACORE_V2_VAULT_ID,
			addressTypes: COUNTRY_ADDRESS_FORM_TYPES,
		};
	},
	methods: {
		lacoreV2FormSetup() {
			// Import VGS Script v2 here to avoid conflict with v1
			const VGSScript = document.createElement('script');
			VGSScript.setAttribute('src', VGS_V2_SCRIPT_SOURCE);
			document.head.appendChild(VGSScript);
			VGSScript.addEventListener('load', () => {
				// VGS Collect form initialization
				this.lacoreV2PaymentForm = VGSCollect.create(this.lacoreV2VaultId, this.lacoreV2Environment, () => {});// eslint-disable-line no-undef
				this.lacoreV2PaymentForm.useCname(process.env.VUE_APP_LACORE_V2_API);
				this.lacoreV2PaymentForm.field('#cc-v2-name', {
					type: 'text',
					name: 'name',
					placeholder: `${this.translate('name_on_card')}`,
					validations: ['required'],
				});
				this.lacoreV2PaymentForm.field('#cc-v2-number', {
					type: 'card-number',
					name: 'number',
					placeholder: `${this.translate('card_number')}`,
					validations: ['required', 'validCardNumber'],
				});
				this.lacoreV2PaymentForm.field('#cc-v2-cvc', {
					type: 'card-security-code',
					name: 'security_code',
					placeholder: `${this.translate('cvv')}`,
					validations: ['required', 'validCardSecurityCode'],
				});
				this.lacoreV2PaymentForm.field('#cc-v2-expiration-month', {
					type: 'number',
					name: 'expiration_month',
					placeholder: `${this.translate('expiry_month')}`,
					validations: ['required'],
				});
				this.lacoreV2PaymentForm.field('#cc-v2-expiration-year', {
					type: 'number',
					name: 'expiration_year',
					placeholder: `${this.translate('expiry_year')} (YYYY)`,
					validations: ['required'],
				});
			});
		},
		async lacoreV2HandleResponse() {
			this.lacoreV2ResetCreditCardErrors();
			this.lacoreV2Failed = false;
			try {
				const smtForm = await this.lacoreV2SubmitForm();
				if (typeof smtForm.total !== 'undefined') {
					this.lacoreV2Failed = true;
					smtForm._embedded.errors.forEach((error) => { // eslint-disable-line no-underscore-dangle
						error.forEach((errorItem) => {
							this.lacoreV2CreditCardErrors[errorItem.field || 'number'].errorMessages.push(errorItem);
						});
					});
				} else if (typeof smtForm.id !== 'undefined') {
					this.lacoreV2PaymentToken = smtForm.id;
				}
			} catch (error) {
				this.lacoreV2Failed = true;
				this.lacoreV2CreditCardErrors = { ...error };
			}
		},
		async lacoreV2SubmitForm() {
			let billingInfo = '';
			if (this.addAddress) {
				billingInfo = this.form.address;
				billingInfo.region = { id: billingInfo.region_id };
				billingInfo.country = { code: billingInfo.country_code };
			} else {
				billingInfo = await this.getBillingDetails.getAddress(this.$user.details().id, this.form.address_id);
				billingInfo = billingInfo.attributes;
			}

			let stateCode = '';
			let countryCode = '';
			let { address2, address } = billingInfo;
			try {
				if (this.addressTypes.address_form_type_2.includes(billingInfo.country.code)) {
					if (typeof billingInfo.address2 !== 'undefined' && billingInfo.address2 !== '') {
						const newAddress = `${billingInfo.address}, ${billingInfo.address2.replace(',', '-')}`;
						address = newAddress;
						address2 = billingInfo.address3;
					}
				}

				if (this.form.address.state_code !== '' && typeof this.form.address.state_code !== 'undefined') {
					stateCode = this.form.address.state_code;
				} else {
					const stateResponse = await this.state.getState(billingInfo.region.id);
					stateCode = stateResponse.attributes.code;
				}
				// const countryResponse = await this.countryIso.getCountry(billingInfo.country.code); // iso_code_3 no longer used
				countryCode = billingInfo.country.code;
			} catch (error) {
				stateCode = '';
			}
			return new Promise((resolve, reject) => {
				this.lacoreV2PaymentForm.submit(`/applications/${ this.lacoreV2ApplicationId }/tokens`, {
					data: {
						type: 'PAYMENT_CARD',
						address: {
							line1: address,
							line2: address2,
							city: billingInfo.city,
							region: stateCode,
							postal_code: billingInfo.postcode,
							country: countryCode,
						},
					},
				}, (status, data) => {
					resolve(data);
				}, (error) => {
					reject(error);
				});
			});
		},
		lacoreV2ResetCreditCardErrors() {
			this.lacoreV2CreditCardErrors = {
				name: {
					errorMessages: [],
				},
				number: {
					errorMessages: [],
				},
				expiration_month: {
					errorMessages: [],
				},
				expiration_year: {
					errorMessages: [],
				},
				security_code: {
					errorMessages: [],
				},
			};
		},
		lacoreV2CreditCardHasErrors(field) {
			return typeof this.lacoreV2CreditCardErrors[field] !== 'undefined' && this.lacoreV2CreditCardErrors[field].errorMessages.length > 0;
		},
	},
};
